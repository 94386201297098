import { createApiRef } from '@backstage/core-plugin-api'

export type VersionDetail = {
  Version: string
  Environment: string
  Platform: string
  OTA: boolean
  OTAHash: string
}

export type App = {
  id: number
  name: string
  repo: string
  argoName: string
  mobile: boolean
  codeVersion: string
  deployedVersions?: VersionDetail[]
}

export type AppType = 'application' | 'utility'

export type CreateAppParams = {
  name: string
  repo: string
  argoName: string
  authGroups: string[]
  requiredApprovalGroups: string[]
  appType: AppType
  approvalCount: number
  multiverseDeploy: boolean
  allowDevDeploys: boolean
  useReleaseBranch: boolean
}

export type Issue = {
  id: string
  title: string
}

export type Commit = {
  commitSha: string
  message: string
}

export type Release = {
  id: number
  status: string
  version: string
  branch: string
  appName: string
  repo: string
  approvals?: string
  updated_at: string
  issues: Issue[]
  commits: Commit[]
  canApprove: boolean
  canUserApprove: boolean
  canUserOverride: boolean
  canCancel: boolean
}

export type ReleaseHistory = {
  id: number
  version: string
  previousVersion: string
  created: string
  deployed: string
  issues: Issue[]
  commits: Commit[]
  appName: string
  repo: string
}

export type IssueList = {
  fromVersion: string
  issues: Issue[]
  toVersion: string
  updatePending: boolean
}

export interface ReleaseManagerApi {
  getHealth(): Promise<{ status: string }>
  getApps(): Promise<App[]>
  getApp(appId: number): Promise<App>
  createApp(app: CreateAppParams): Promise<void>
  createRelease(appId: number, isMajor: boolean): Promise<Release>
  getRelease(releaseId: number): Promise<Release>
  getReleaseByVersion(appId: number, version: string): Promise<Release>
  getActiveReleases(): Promise<Release[]>
  getActiveRelease(appId: number): Promise<Release | undefined>
  queryCompletedReleases(deployedDateStart: string, deployedDateEnd?: string): Promise<ReleaseHistory[]>
  getAppIssues(appId: number): Promise<IssueList>
  approveRelease(releaseId: number, status: string, platforms: string[]): Promise<void>
  overrideApproval(releaseId: number, status: string, platforms: string[]): Promise<void>
  cancelRelease(releaseId: number): Promise<void>
}

export const releaseManagerApiRef = createApiRef<ReleaseManagerApi>({
  id: 'plugin.release-manager.service',
})
