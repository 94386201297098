import {
  createApiFactory,
  createPlugin,
  createRoutableExtension, discoveryApiRef, identityApiRef,
} from '@backstage/core-plugin-api';

import {homeRouteRef, releaseShowRouteRef, appShowRouteRef} from './routes';
import {releaseManagerApiRef} from "./api";
import ReleaseManagerApiClient from "./api/ReleaseManagerApiClient";

export const prizepicksReleaseManagerPlugin = createPlugin({
  id: 'prizepicks-release-manager',
  apis: [
    createApiFactory({
      api: releaseManagerApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        identityApi: identityApiRef
      },
      factory: ({ discoveryApi, identityApi }) =>
        new ReleaseManagerApiClient({ discoveryApi, identityApi }),
    }),
  ],
  routes: {
    home: homeRouteRef,
    releaseShow: releaseShowRouteRef,
    appShow: appShowRouteRef
  }
});

export const AppsListPage = prizepicksReleaseManagerPlugin.provide(
  createRoutableExtension({
    name: 'AppsListPage',
    component: () =>
      import('./components/ReleasesHomePage').then(m => m.ReleasesHomePage),
    mountPoint: homeRouteRef,
  }),
);

export const AppShowPage = prizepicksReleaseManagerPlugin.provide(
  createRoutableExtension({
    name: 'AppShowPage',
    component: () =>
      import('./components/AppShowPage').then(m => m.AppShowPage),
    mountPoint: appShowRouteRef,
  })
)

export const ReleaseShowPage = prizepicksReleaseManagerPlugin.provide(
  createRoutableExtension({
    name: 'ReleaseShowPage',
    component: () =>
      import('./components/ReleaseShowPage').then(m => m.ReleaseShowPage),
    mountPoint: releaseShowRouteRef,
  }),
);