import {DiscoveryApi, IdentityApi} from "@backstage/core-plugin-api";
import {App, CreateAppParams, IssueList, Release, ReleaseHistory, ReleaseManagerApi} from "./types";

export class ApiError extends Error {
  name: string
  statusCode: number

  constructor(message: string, statusCode: number) {
    super(message)
    this.name = this.constructor.name
    this.statusCode = statusCode
    Error.captureStackTrace(this, this.constructor)
  }
}

export class BadRequestError extends ApiError {
  errorCode: number
  details: string[]

  constructor(message: string, errorCode: number, details: string[]) {
    super(message, 400)
    this.errorCode = errorCode
    this.details = details
  }
}

export class ValidationError extends ApiError {
  errorCode: number
  details: string[]

  constructor(message: string, errorCode: number, details: string[]) {
    super(message, 422)
    this.errorCode = errorCode
    this.details = details
  }
}

export class ReleaseManagerApiClient implements ReleaseManagerApi {
  private readonly discoveryApi: DiscoveryApi
  private readonly identityApi: IdentityApi
  constructor(options: {
    discoveryApi: DiscoveryApi,
    identityApi: IdentityApi
  }) {
    this.discoveryApi = options.discoveryApi
    this.identityApi = options.identityApi
  }
  async getHealth(): Promise<{ status: string }> {
    const baseUrl =   await this.discoveryApi.getBaseUrl('release_manager')
    const response = await fetch(`${baseUrl}/health`)
    if (!response.ok) {
      throw new ApiError(response.statusText, response.status)
    }
    return await response.json()
  }

  async getApps(): Promise<App[]> {
    const { token: idToken } = await this.identityApi.getCredentials();
    const baseUrl =   await this.discoveryApi.getBaseUrl('release_manager')
    const response = await fetch(`${baseUrl}/releases`, {
      method: 'GET',
      headers: {
        ...(idToken && {Authorization: `Bearer ${idToken}`}),
      }
    })
    if (!response.ok) {
      throw new ApiError(response.statusText, response.status)
    }
    return await response.json()
  }

  async getApp(appId: number): Promise<App> {
    const { token: idToken } = await this.identityApi.getCredentials();
    const baseUrl =   await this.discoveryApi.getBaseUrl('release_manager')
    const response = await fetch(`${baseUrl}/apps/${appId}`, {
      method: 'GET',
      headers: {
        ...(idToken && {Authorization: `Bearer ${idToken}`}),
        'Content-Type': 'application/json'
      }
    })
    if (!response.ok) {
      throw new ApiError(response.statusText, response.status)
    }
    return await response.json()
  }

  async createApp(app: CreateAppParams): Promise<void> {
    const { token: idToken } = await this.identityApi.getCredentials();
    const baseUrl =   await this.discoveryApi.getBaseUrl('release_manager')
    const body = JSON.stringify(app)
    const response = await fetch(`${baseUrl}/apps`, {
      method: 'POST',
      headers: {
        ...(idToken && {Authorization: `Bearer ${idToken}`}),
        'Content-Type': 'application/json'
      },
      body
    })
    if (!response.ok) {
      throw new ApiError(response.statusText, response.status)
    }
  }

  async createRelease(appId: number, isMajor: boolean): Promise<Release> {
    const { token: idToken } = await this.identityApi.getCredentials();
    const baseUrl =   await this.discoveryApi.getBaseUrl('release_manager')
    const body = JSON.stringify({ isMajor })
    const response = await fetch(`${baseUrl}/apps/${appId}/release`, {
      method: 'POST',
      headers: {
        ...(idToken && {Authorization: `Bearer ${idToken}`}),
        'Content-Type': 'application/json'
      },
      body
    })
    if (!response.ok) {
      const result = await response.json()
      switch (response.status) {
        case 400:
          throw new BadRequestError(result.message, result.errorCode, result.details)
        case 422:
          throw new ValidationError(result.message, result.errorCode, result.details)
        default:
          throw new ApiError(response.statusText, response.status)
      }
    }
    return await response.json()
  }

  async getRelease(releaseId: number): Promise<Release> {
    const { token: idToken } = await this.identityApi.getCredentials();
    const baseUrl =   await this.discoveryApi.getBaseUrl('release_manager')
    const response = await fetch(`${baseUrl}/release/${releaseId}`, {
      method: 'GET',
      headers: {
        ...(idToken && {Authorization: `Bearer ${idToken}`}),
      'Content-Type': 'application/json'
      }
    })
    if (!response.ok) {
      throw new ApiError(response.statusText, response.status)
    }
    return await response.json()
  }

  async getReleaseByVersion(appId: number, version: string): Promise<Release> {
    const { token: idToken } = await this.identityApi.getCredentials();
    const baseUrl =   await this.discoveryApi.getBaseUrl('release_manager')
    const response = await fetch(`${baseUrl}/apps/${appId}/releases/${version}`, {
      method: 'GET',
      headers: {
        ...(idToken && {Authorization: `Bearer ${idToken}`}),
        'Content-Type': 'application/json'
      }
    })
    if (!response.ok) {
      throw new ApiError(response.statusText, response.status)
    }
    return await response.json()
  }

  async getActiveReleases(): Promise<Release[]> {
    const { token: idToken } = await this.identityApi.getCredentials();
    const baseUrl =   await this.discoveryApi.getBaseUrl('release_manager')
    const response = await fetch(`${baseUrl}/release/active`, {
      method: 'GET',
      headers: {
        ...(idToken && {Authorization: `Bearer ${idToken}`}),
        'Content-Type': 'application/json'
      }
    })
    if (!response.ok) {
      throw new ApiError(response.statusText, response.status)
    }
    return await response.json()
  }

  async getActiveRelease(appId: number): Promise<Release | undefined> {
    const { token: idToken } = await this.identityApi.getCredentials();
    const baseUrl =   await this.discoveryApi.getBaseUrl('release_manager')
    const response = await fetch(`${baseUrl}/app/${appId}/release/active`, {
      method: 'GET',
      headers: {
        ...(idToken && {Authorization: `Bearer ${idToken}`}),
        'Content-Type': 'application/json'
      }
    })
    if (!response.ok) {
      throw new ApiError(response.statusText, response.status)
    }
    return await response.json()
  }

  async queryCompletedReleases(deployedDateStart: string, deployedDateEnd: string | undefined): Promise<ReleaseHistory[]> {
    const { token: idToken } = await this.identityApi.getCredentials();
    const baseUrl =   await this.discoveryApi.getBaseUrl('release_manager')
    const url = new URL(`${baseUrl}/release/completed`)
    url.searchParams.set('deployedDateStart', deployedDateStart)
    if (deployedDateEnd) {
      url.searchParams.set('deployedDateEnd', deployedDateEnd)
    }
    const response = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        ...(idToken && {Authorization: `Bearer ${idToken}`}),
        'Content-Type': 'application/json'
      }
    })
    if (!response.ok) {
      throw new ApiError(response.statusText, response.status)
    }
    return await response.json()
  }

  async getAppIssues(appId: number): Promise<IssueList> {
    const { token: idToken } = await this.identityApi.getCredentials()
    const baseUrl =   await this.discoveryApi.getBaseUrl('release_manager')
    const url = new URL(`${baseUrl}/apps/${appId}/issues`)
    const response = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        ...(idToken && {Authorization: `Bearer ${idToken}`}),
        'Content-Type': 'application/json'
      }
    })
    if (!response.ok) {
      throw new ApiError(response.statusText, response.status)
    }
    return await response.json()
  }

  async approveRelease(releaseId:number, status:string, platforms: string[]): Promise<void> {
    const { token: idToken } = await this.identityApi.getCredentials();
    const baseUrl =   await this.discoveryApi.getBaseUrl('release_manager')
    const url = new URL(`${baseUrl}/release/${releaseId}/approve/${status}`)
    const body = JSON.stringify({ platforms })
    const response = await fetch(url.toString(), {
      method: 'PUT',
      headers: {
        ...(idToken && {Authorization: `Bearer ${idToken}`}),
        'Content-Type': 'application/json'
      },
      body
    })
    if (!response.ok) {
      throw new ApiError(response.statusText, response.status)
    }
  }

  async overrideApproval(releaseId: number, status:string, platforms: string[]): Promise<void> {
    const { token: idToken } = await this.identityApi.getCredentials();
    const baseUrl =   await this.discoveryApi.getBaseUrl('release_manager')
    const url = new URL(`${baseUrl}/release/${releaseId}/override-approve/${status}`)
    const body = JSON.stringify({ platforms })
    const response = await fetch(url.toString(), {
      method: 'PUT',
      headers: {
        ...(idToken && {Authorization: `Bearer ${idToken}`}),
        'Content-Type': 'application/json'
      },
      body
    })
    if (!response.ok) {
      throw new ApiError(response.statusText, response.status)
    }
  }

  async cancelRelease(releaseId: number): Promise<void> {
    const { token: idToken } = await this.identityApi.getCredentials();
    const baseUrl =   await this.discoveryApi.getBaseUrl('release_manager')
    const url = new URL(`${baseUrl}/release/${releaseId}`)
    const response = await fetch(url.toString(), {
      method: 'DELETE',
      headers: {
        ...(idToken && {Authorization: `Bearer ${idToken}`}),
        'Content-Type': 'application/json'
      }
    })
    if (!response.ok) {
      throw new ApiError(response.statusText, response.status)
    }
  }
}

export default ReleaseManagerApiClient
