import { createRouteRef } from '@backstage/core-plugin-api';

export const homeRouteRef = createRouteRef({
  id: 'release-manager:home-ref',
});

export const releaseShowRouteRef = createRouteRef({
  id: 'release-manager:release-ref',
  params: ['appId', 'releaseId']
})

export const appShowRouteRef = createRouteRef({
  id: 'release-manager:app-ref',
  params: ['appId']
})
